import React from "react";
import ScheduleCall from "views/ScheduleACall";
import { Helmet } from "react-helmet";

const ScheduleACallPage = () => {
    return (
      <>
        <Helmet>
          <title>Sonnul | Web Development & Digital Marketing Agency</title>
          <meta
            name="description"
            content="Web Services & Digital Marketing Agency in the Greater Sacramento area"
          />
          <meta property="og:title" content="Sonnul" />
          <meta
            property="og:description"
            content="Web Services & Digital Marketing Agency in the Greater Sacramento area"
          />
          <meta property="og:image" content="/images/SonnulLogoBlue.jpg" />
          <meta property="og:url" content={`https://www.sonnul.com/`} />
          <meta name="twitter:title" content="Sonnul" />
          <meta
            name="twitter:description"
            content="Web Services & Digital Marketing Agency in the Greater Sacramento area"
          />
          <meta name="twitter:image" content="/images/SonnulLogoBlue.jpg" />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <ScheduleCall />{" "}
      </>
    );
};

export default ScheduleACallPage;
