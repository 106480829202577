import React  from "react";
import Main from "layouts/Main";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  FAQ,
  CurrentPageNavText,
  BannerWithButton,
  BigTextHeader,
} from "customComponents";
import {Box } from "@mui/material";

const ScheduleACall = () => {
      const theme = useTheme();
      const isMd = useMediaQuery(theme.breakpoints.up("md"), {
        defaultMatches: true,
      });
  return (
    <Main>
      <BigTextHeader
        headerText={"Talk To An Expert"}
        subheaderText={""}
        showButton={false}
      />
      <CurrentPageNavText navLocText={"Home > Schedule Call"} />

      <Box paddingY={isMd ? 10 : 0}>
        <iframe
          src="https://app.hellobonsai.com/s/sonnul/69a36ddd-af3e-43f6-b4d5-dd3babb45cd8?embed=true"
          style={{
            height: isMd ? "700px" : "1100px",
            width: "100%",
            border: "none",
          }}
        ></iframe>
      </Box>
      <FAQ />
      <BannerWithButton
        text1="Schedule a quick call with our team."
        text2="We're excited to see what you have in mind!"
        buttonText="Schedule Call"
        buttonAltText="Free Consultation"
        linkUrl="/schedule-call"
      />
    </Main>
  );
};

export default ScheduleACall;
